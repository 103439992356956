import axios from 'axios'
import router from '../router'
import store from '../store'
import { ElMessage } from 'element-plus'

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,  // 注意！！ 这里是全局统一加上了 后端接口前缀 前缀，后端必须进行跨域配置！
    timeout: 5000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['Authorization'] = localStorage.getItem("token");  // 设置请求头
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        // 如果是返回的文件
        if (response.data.code === 200) {
            if(response.headers.authorization){
                const jwt = response.headers.authorization
                store.commit("SET_TOKEN", jwt)
                return response
            };
            return response;
        }else {

            ElMessage.error(response.data.msg)

            return Promise.reject(response.data.msg)
        }
        // if (res.config.responseType === 'blob') {
        //     return res
        // }
        // // 兼容服务端返回的字符串数据
        // if (typeof res === 'string') {
        //     res = res ? JSON.parse(res) : res
        // }
        // return res;
    },
    error => {
        if(error.response.data) {
            error.message = error.response.data.msg
        }

        if(error.response.status === 500) {
            store.commit("REMOVE_INFO")
            router.push("/login")
        }

        ElMessage.error(error.message)
        return Promise.reject(error)
    }
)


export default request

