<template>
  <div>
    <Header></Header>
    <div style="display: flex">
      <AsideMenu></AsideMenu>
      <router-view style="flex: 1;"/>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AsideMenu from "@/components/AsideMenu";

export default {
  name: "Layout",
  components: {
    Header,
    AsideMenu
  }
}
</script>

<style scoped>

</style>
