<template>
  <div>
    <el-menu
        :default-active=$route.path
        style="width: 200px; min-height: calc(100vh - 80px)"
        class="el-menu-vertical-demo"
        router
    >
      <el-menu-item index="/user" route="/user">
        <el-icon><user-filled /></el-icon>
        <span>用户管理</span>
      </el-menu-item>
      <el-menu-item index="/comment" route="/comment">
        <el-icon><chat-line-square /></el-icon>
        <span>评论管理</span>
      </el-menu-item>
      <el-menu-item index="/news" route="/news">
        <el-icon><promotion /></el-icon>
        <span>新闻管理</span>
      </el-menu-item>
      <el-menu-item index="/article" route="/article">
        <el-icon><document /></el-icon>
        <span>文章管理</span>
      </el-menu-item>
      <el-menu-item index="/videos" route="/videos">
        <el-icon><video-camera-filled /></el-icon>
        <span>视频管理</span>
      </el-menu-item>
      <el-sub-menu index="/question">
        <template #title>
          <el-icon><checked /></el-icon>
          <span>问卷管理</span>
        </template>
        <el-menu-item index="/questionList" route="/questionList">问卷列表</el-menu-item>
        <el-menu-item index="/paperList" route="/paperList">题目管理</el-menu-item>
        <el-menu-item index="/questionCountList" route="/questionCountList">问卷统计</el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="/data">
        <template #title>
          <el-icon><data-analysis /></el-icon>
          <span>数据管理</span>
        </template>
        <el-menu-item index="/data" route="/data">上报数据</el-menu-item>
<!--        <el-menu-item index="1-2">报表</el-menu-item>-->
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import {
  Location,
  Document,
  Menu as IconMenu,
  Setting,
  UserFilled,
  DataAnalysis,
  VideoCameraFilled,
  ChatLineSquare,
    Checked,
    Promotion,
} from '@element-plus/icons-vue'
export default {
  name: "AsideMenu",
  components:{
    Location,
    Document,
    IconMenu,
    Setting,
    UserFilled,
    DataAnalysis,
    VideoCameraFilled,
    ChatLineSquare,
    Checked,
    Promotion,
  },
}

</script>

<style scoped>

</style>
