import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout/Layout";

const routes = [

  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: "/comment",
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/user',
        name: 'User',
        component: () => import("@/views/User"),

      },
      {
        path: '/comment',
        name: 'Comment',
        component: () => import("@/views/Comment"),

      },
      {
        path: '/data',
        name: 'Data',
        component: ()=>import("@/views/Data")
      },
      {
        path: '/news',
        name: 'News',
        component: ()=>import("@/views/News"),
      },
      {
        path: '/article',
        name: 'Article',
        component: ()=>import("@/views/Article"),
      },
      {
        path: '/videos',
        name: 'Videos',
        component: ()=>import("@/views/Videos"),
      },
      {
        path: '/questionList',
        name: 'QuestionList',
        component: ()=>import("@/views/QuestionList"),
      },
      {
        path: '/paperList',
        name: 'PaperList',
        component: ()=>import("@/views/PaperList"),
      },
      {
        path: '/questionCountList',
        name: 'QuestionCountList',
        component: ()=>import("@/views/QuestionCountList"),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: ()=>import("@/views/Login")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
