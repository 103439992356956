<template>
  <div style="height: 80px; border-bottom: 1px solid #ccc; display: flex">
    <div style="width: 360px; display: flex; justify-content: space-evenly; align-items: center">
      <img src="../assets/img/logo.png" style="height: 70px; margin-left: 10px">
      <div style="font-size: 30px; font-weight: bold; color: #003f99">心力衰竭慢病管理</div>
    </div>
    <div style="flex: 1">
    </div>
    <div style="width: 150px;display: flex;justify-content: center;align-items: end">
      <el-dropdown style="margin-bottom: 10px">
        <span class="el-dropdown-link">
          管理员
          <el-icon class="el-icon--right">
            <arrow-down/>
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item @click="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dialog title="修改密码" v-model="dialogVisible" width="50%">
        <el-form :model="form" label-width="120px">
          <el-form-item label="旧密码">
            <el-input type="text" v-model="form.oldPassword" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input type="text" v-model="form.newPassword" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item label="再次输入密码">
            <el-input type="text" v-model="form.copyPassword" style="width: 80%"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleChange">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    </div>

</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue';
import request from "@/utils/request";


export default {
  name: "Header",

  components:{
    ArrowDown
  },
  data(){
    return{
      dialogVisible:false,
      form: {
        oldPassword:"",
        newPassword:"",
        copyPassword:""
      },
    }
  },

  methods:{
    logout(){
      const _this = this
      _this.$store.commit("REMOVE_INFO")
      _this.$router.push("/login")
    },
    changePassword(){
      this.form = {};
      this.dialogVisible= true
    },
    handleChange(){
      this.form.pid = 0;
      if(this.form.oldPassword == null || this.form.oldPassword == "" ){
        this.$message.warning("旧密码不能为空")
      }else if(this.form.newPassword == null || this.form.newPassword == ""){
        this.$message.warning("新密码不能为空")
      }else if(this.form.newPassword == this.form.oldPassword){
        this.$message.warning("新码密码不能与旧密码一致")
      }else if(this.form.newPassword !== this.form.copyPassword){
        this.$message.warning("再次输入的密码不一致")
      }else{
        //this.$message.warning("再次输入的密码不一致")
        request.post("/changePassword",this.form).then(res => {
          if (res.data.code === 200){
            this.$message({
              type: "success",
              message: res.data.msg
            })
            this.dialogVisible = false
          }else {
            this.$message({
              type: "error",
              message: res.data.msg
            })
          }
        })
      }
    },
  },
}


</script>


<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #003f99;
  display: flex;
  align-items: center;
  font-size:  large;
  font-weight: bold;
}
</style>


